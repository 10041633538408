<template>
    <y-modal
        ref="Modal"
        :title="title"
    >
        <div slot="body">
            <form
                id="group-form"
                @submit.prevent="submit(save)"
            >
                <y-form-field
                    v-for="locale in locales"
                    :key="`title-${locale}`"
                    v-model="model.title[locale]"
                    type="text"
                    :name="`title-${locale}`"
                    validation="required"
                    :label="$t('specs.group.locale_title', { locale: $t(`general.locales.${locale}`) })"
                />
            </form>
        </div>

        <template slot="footer">
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="blue"
                    form="group-form"
                    loading-on="submitting-form"
                >
                    {{ $t('button.save') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import FormMixin from '@/mixins/Form';
    import { YModal, YFormField } from '@deps';

    export default {
        name: 'GroupModal',

        components: {
            YModal,
            YFormField,
        },

        mixins: [FormMixin],

        props: {
            locales: {
                type: Array,
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                edit : false,
                index: null,

                model: {
                    title: {},
                },
            };
        },

        computed: {
            /**
             * Return modal title
             */
            title() {
                return this.edit ? this.$t('specs.group.edit_modal') : this.$t('specs.group.create_modal');
            },

            /**
             * Check title counts equal to entered title
             */
            titleCounts() {
                const model = !this.isEmpty(this.model.title);
                const count = Object.keys(this.model.title).length === this.locales.length;
                let emptyTitle = true;
                if (model) {
                    Object.values(this.model.title).forEach((title) => {
                        if (!title) {
                            emptyTitle = false;
                        }
                    });
                }
                return model && emptyTitle && count;
            },
        },

        methods: {

            /**
             * Save form
             */
            save() {
                return new Promise((resolve) => {
                    if (this.titleCounts) {
                        this.$emit('save', {
                            edit : this.edit,
                            index: this.index,
                            model: this.model,
                        });
                        this.close();
                        resolve();
                    } else {
                        this.$toast.error(this.$t('specs.messages.title'));
                    }
                    resolve();
                });
            },

            /**
             * Check if object is empty
             *
             * @param obj
             */
            isEmpty(obj) { // eslint-disable-line consistent-return
                if ((typeof obj === 'object') && (obj !== null)) {
                    return (Object.entries(obj).length === 0 && obj.constructor === Object);
                }
            },

            /**
             * Open modal
             *
             * @param edit
             * @param index
             * @param model
             */
            open(edit = false, index = null, model = null) {
                this.edit = edit;
                this.index = index;
                if (model) {
                    this.model = model;
                }
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.reset();
            },

            /**
             * Reset modal data
             */
            reset() {
                this.edit = false;
                this.index = null;
                this.model = {
                    title: {},
                };
            },

        },
    };
</script>
