<template>
    <y-panel-grid :service="$services.Specs.specsFormsGrid" />
</template>

<script>

    // Mixins
    import GridPageMixin from '@/mixins/GridPage';

    export default {

        name: 'SpecsList',

        mixins: [GridPageMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t('breadcrumbs.specs.list')),
            ];
        },
    };
</script>
