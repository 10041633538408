<template>
    <y-panel
        :title="localizedTitle(model.title)"
        icon="md-menu"
        class="slide-item"
        collapsible
    >
        <template slot="functions">
            <y-button
                size="xs"
                color="blue"
                @click.native.prevent="$refs.SpecItemModal.open(false, index)"
            >
                {{ $t('button.specs.add_spec') }}
            </y-button>

            <y-button
                size="xs"
                class="color-green"
                @click.native.prevent="startEditingGroup"
            >
                {{ $t('button.edit') }}
            </y-button>

            <y-button
                size="xs"
                class="color-red"
                @click.native.prevent="startDeletingGroup"
            >
                {{ $t('button.delete') }}
            </y-button>
        </template>

        <draggable
            v-if="hasChildren"
            v-bind="dragOptions"
            ref="Draggable"
            handle=".slide-item header .md-menu"
            class="m0 p0"
            tag="ul"
            :list="model.children"
            :group="{ name: 's1' }"
        >
            <template v-for="(item, itemIndex) in model.children">
                <y-spec-item
                    v-if="!item.deleted"
                    :key="`spec-item-${itemIndex}`"
                    :title="localizedTitle(item.title)"
                    :type="item.type"
                    :group-index="index"
                    :index="itemIndex"
                    @delete="startDeletingSpec"
                    @editSpec="startEditingSpec"
                />
            </template>
        </draggable>

        <!-- Empty state -->
        <y-empty-state
            v-else
            :message="$t('specs.empty.spec')"
            no-image
            :height="60"
            class="mb0"
        />

        <!-- Modals -->
        <y-spec-modal
            ref="SpecItemModal"
            :locales="locales"
            @save="saveSpec"
        />
    </y-panel>
</template>

<script>

    import Draggable from 'vuedraggable';

    import YEmptyState from '@deps/EmptyState';
    import YSpecItem from '@/modules/specs/components/SpecItem';
    import YSpecModal from '@/modules/specs/components/SpecModal';

    export default {

        name: 'SpecGroupItem',

        components: {
            Draggable,
            YEmptyState,
            YSpecModal,
            YSpecItem,
        },

        props: {
            /**
             * Input Value
             */
            value: Object,

            /**
             * Item index
             */
            index: Number,

            locales: {
                type: Array,
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},
            };
        },

        computed: {
            /**
             * @inheritDoc
             */
            dragOptions() {
                return {
                    animation : 200,
                    ghostClass: 'ghost',
                };
            },

            /**
             * Check if has children
             */
            hasChildren() {
                return this.model.children
                    && this.model.children.length
                    && this.model.children.filter((el) => !el.deleted).length;
            },
        },

        watch: {

            value: {
                /**
                 * Handle watch value
                 *
                 * @param val
                 */
                handler(val) {
                    this.model = val;
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

        },

        methods: {

            /**
             * Remove group
             */
            startDeletingGroup() {
                const payload = {
                    deleteType: 'group',
                    index     : this.index,
                    ...this.model,
                };
                this.$emit('delete', payload);
            },

            /**
             * Remove spec item
             *
             * @param itemPayload
             */
            startDeletingSpec(itemPayload) {
                const itemIndex = itemPayload.index;
                const item = this.model.children[itemIndex];

                const payload = {
                    ...item,
                    index     : itemIndex,
                    deleteType: 'spec',
                    groupIndex: this.index,
                };

                this.$emit('delete', payload);
            },

            /**
             * Remove group
             */
            removeItem() {
                const payload = {
                    type : 'group',
                    index: this.index,
                };
                this.$emit('delete', payload);
            },

            /**
             * Save spec item
             *
             * @param payload
             */
            saveSpec(payload) {
                this.$emit('saveSpec', payload);
            },

            /**
             * Start editing group
             */
            startEditingGroup() {
                this.$emit('editGroup', this.index);
            },

            /**
             * Editing spec item
             *
             * @param payload
             */
            startEditingSpec(payload) {
                const groupIndex = this.index;
                const itemIndex = payload.index;
                const item = this.model.children[itemIndex];

                this.$refs.SpecItemModal.open(true, groupIndex, itemIndex, this.cloneDeep(item));
            },

            /**
             * return title whether in current locale or in fallback locale
             *
             * @param titleModel
             */
            localizedTitle(titleModel) {
                const { locale, fallbackLocale } = this.$i18n;
                
                return locale in titleModel
                    ? titleModel[locale]
                    : titleModel[fallbackLocale];
            },

        },

    };
</script>
