<template>
    <y-panel
        class="specs-sidebar"
        :title="$t('specs.sidebar')"
    >
        <ul v-if="groups">
            <template v-for="(group, index) in refinedGroups">
                <li
                    v-if="!group.deleted"
                    :key="`side-group-${index}`"
                >
                    <a
                        v-scroll-to="`#group-item-${index}`"
                        href="#"
                    >
                        {{ group.title }}
                    </a>
                </li>
            </template>
        </ul>
    </y-panel>
</template>

<script>
    export default {

        name: 'Sidebar',

        props: {
            /**
             * Array of groups to link
             */
            groups: Array,
        },

        computed: {
            /**
             * Remodel groups based on locales
             */
            refinedGroups() {
                const { locale, fallbackLocale } = this.$i18n;

                const newGroups = this.groups.map((group) => ({
                    ...group,
                    title: locale in group.title
                        ? group.title[locale]
                        : group.title[fallbackLocale],
                }));
                
                return newGroups;
            },
        },

    };
</script>
