<template>
    <div>
        <!-- Page Head -->
        <y-page-head
            icon="md-playlist-star"
            :title="$t('specs.title')"
        >
            <template slot="actions">
                <y-button
                    color="green"
                    form="main-form"
                    loading-on="submitting-form"
                >
                    {{ $t('button.save') }}
                </y-button>

                <y-button
                    class="color-blue"
                    disabled-on="submitting-form"
                    @click.native.prevent="$refs.GroupModal.open()"
                    @save="saveGroup"
                >
                    {{ $t('button.specs.add_group') }}
                </y-button>
            </template>
        </y-page-head>
        <!-- Page Head -->

        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 239px )"
        >
            <main>
                <div
                    v-if="model.items && model.items.length"
                    class="row"
                    :class="[{disabled: $wait.is('submitting-form')}]"
                >
                    <!-- Sidebar -->
                    <div class="col-sm-3">
                        <y-sidebar :groups="model.items" />
                    </div>

                    <!-- Items -->
                    <div class="col-sm-9">
                        <form
                            id="main-form"
                            @submit.prevent="submit(save)"
                        >
                            <draggable
                                v-bind="dragOptions"
                                ref="Draggable"
                                handle=".slide-item header .md-menu"
                                class="m0 p0"
                                tag="ul"
                                :list="model.items"
                                :group="{ name: 'g1' }"
                            >
                                <template v-for="(item, index) in model.items">
                                    <y-group-item
                                        v-if="!item.deleted"
                                        :id="`group-item-${index}`"
                                        :key="`group-item-${index}`"
                                        v-model="model.items[index]"
                                        :index="index"
                                        :locales="locales"
                                        @delete="$refs.DeleteModal.open($event)"
                                        @saveSpec="saveSpec"
                                        @editGroup="startEditingGroup"
                                    />
                                </template>
                            </draggable>
                        </form>
                    </div>
                </div>

                <y-empty-state
                    v-else
                    :message="$t('specs.empty.group')"
                    :height="300"
                />
            </main>
        </y-loading>

        <!-- Modals -->
        <y-group-modal
            ref="GroupModal"
            :locales="locales"
            @save="saveGroup"
        />

        <y-delete-modal
            ref="DeleteModal"
            @removeGroup="removeGroup"
            @removeSpec="removeSpec"
        />
    </div>
</template>

<script>

    import Draggable from 'vuedraggable';

    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';

    import YEmptyState from '@deps/EmptyState';
    import YSidebar from '@/modules/specs/components/Sidebar';
    import YGroupModal from '@/modules/specs/components/GroupModal';
    import YGroupItem from '@/modules/specs/components/GroupItem';
    import YDeleteModal from '@/modules/specs/components/DeleteModal';

    export default {

        name: 'SpecsManage',

        components: {
            Draggable,
            YEmptyState,
            YSidebar,
            YGroupModal,
            YGroupItem,
            YDeleteModal,
        },

        mixins: [PageMixin, FormMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc('specs', this.$t('breadcrumbs.specs.list')),
                this.$bc(this.$t('breadcrumbs.specs.manage')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('specs.title'),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: {
                    items: null,
                },

                locales: [this.$i18n.locale],
            };
        },

        computed: {
            /**
             * @inheritDoc
             */
            dragOptions() {
                return {
                    animation : 200,
                    ghostClass: 'ghost',
                };
            },
        },

        methods: {

            /**
             * Fetch
             */
            fetch() {
                const params = {
                    id: this.$route.params.id,
                };
                return this.$services.Specs.fetch(params).then((response) => {
                    this.locales = response.data.metadata.locales;
                    this.model.items = this.sort(response.data.results.items);
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Sort items
             *
             * @param items
             */
            sort(items) {
                const results = items.sort(this.sortHelper);
                return results.map((item) => {
                    // eslint-disable-next-line no-param-reassign
                    item.children = item.children.sort(this.sortHelper);
                    return item;
                });
            },

            /**
             * Sort helper
             *
             * @param a
             * @param b
             */
            sortHelper(a, b) {
                return a.order > b.order ? 1 : -1;
            },

            /**
             * Save
             */
            save() {
                const params = {
                    id: this.$route.params.id,
                    ...this.model,
                };
                return this.$services.Specs.save(params).then(() => {
                    this.$toast.success(this.$t('specs.messages.save'));
                    this.reFetch();
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Save group
             *
             * @param payload
             */
            saveGroup(payload) {
                const { edit, index, model } = payload;

                if (!edit) {
                    this.model.items.push({
                        id      : 0,
                        children: [],
                        ...model,
                    });
                    this.$toast.success(this.$t('specs.messages.group.add'));
                } else {
                    this.$set(this.model.items, index, this.cloneDeep(model));
                    this.$toast.success(this.$t('specs.messages.group.update'));
                }
            },

            /**
             * Remove a group from items
             *
             * @param index
             */
            removeGroup(index) {
                this.$set(this.model.items[index], 'deleted', true);
            },

            /**
             * Remove a group from items
             *
             * @param payload
             */
            removeSpec(payload) {
                const { groupIndex, index } = payload;
                this.$set(this.model.items[groupIndex].children[index], 'deleted', true);
            },

            /**
             * Save spec item
             *
             * @param payload
             */
            saveSpec(payload) {
                const {
                    edit, groupIndex, index, model,
                } = payload;

                if (!edit) {
                    this.model.items[groupIndex].children.push({
                        id: 0,
                        ...model,
                    });
                    this.$toast.success(this.$t('specs.messages.spec.add'));
                } else {
                    this.$set(this.model.items[groupIndex].children, index, this.cloneDeep(model));
                    this.$toast.success(this.$t('specs.messages.spec.update'));
                }
            },

            /**
             * Start editing group
             *
             * @param index
             */
            startEditingGroup(index) {
                this.$refs.GroupModal.open(true, index, this.cloneDeep(this.model.items[index]));
            },

        },

    };
</script>
