<template>
    <y-panel
        :title="truncate(title)"
        :subtitle="subtitle"
        icon="md-menu"
        class="slide-item spec-item"
    >
        <template slot="functions">
            <y-button
                key="up"
                size="xs"
                class="color-green"
                @click.native.prevent="startEditingSpec"
            >
                {{ $t('button.edit') }}
            </y-button>

            <y-button
                key="del"
                size="xs"
                class="color-red"
                @click.native.prevent="startDeletingSpec"
            >
                {{ $t('button.delete') }}
            </y-button>
        </template>
    </y-panel>
</template>

<script>

    import { truncate } from '@nodes/helpers/string';

    export default {

        name: 'SpecItem',

        props: {
            /**
             * Item
             */
            title: String,

            /**
             * Type
             */
            type: String,

            /**
             * Group index
             */
            groupIndex: Number,

            /**
             * Item index
             */
            index: Number,
        },

        computed: {
            /**
             * Subtitle of item
             */
            subtitle() {
                return `(${this.$t(`specs.types.${this.type}`)})`;
            },
        },

        methods: {

            /**
             * Remove group
             */
            startDeletingSpec() {
                const payload = {
                    index: this.index,
                };
                this.$emit('delete', payload);
            },

            /**
             * Start editing spec
             */
            startEditingSpec() {
                const payload = {
                    index: this.index,
                };
                this.$emit('editSpec', payload);
            },

            truncate,

        },

    };
</script>
