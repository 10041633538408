<template>
    <y-modal ref="Modal">
        <div
            v-if="item.title"
            slot="body"
        >
            <p
                class="mb0"
                v-html="$t(`specs.delete.${item.deleteType}`, { title: item.title[$i18n.locale] })"
            />
        </div>

        <template slot="footer">
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="red"
                    @click.prevent.native="doDelete"
                >
                    {{ $t('button.delete') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import { YModal } from '@deps';

    export default {
        name: 'DeleteModal',

        components: {
            YModal,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                item: {
                    deleteType: 'group',
                },
            };
        },

        computed: {
            /**
             * Return modal title
             */
            title() {
                return this.$t(`specs.delete.title_${this.item.deleteType}`);
            },
        },

        methods: {

            /**
             * Remove item
             */
            doDelete() {
                // eslint-disable-next-line no-unused-expressions
                this.item.deleteType === 'group'
                    ? this.$emit('removeGroup', this.item.index)
                    : this.$emit('removeSpec', this.item);
                this.close();
            },

            /**
             * Open modal
             *
             * @param payload
             */
            open(payload) {
                this.item = { ...payload };
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.reset();
            },

            /**
             * Reset modal data
             */
            reset() {
                this.item = {
                    deleteType: 'group',
                };
            },

        },
    };
</script>
